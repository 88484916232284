.wrapper {
  display: block;
  padding: 30px 0;
  color: #fff;
}

.text {
  margin: 10px 0 25px;
  color: #fff;
}

.couponCode {
  padding-bottom: 20px;
  color: #fff;
}

.couponCode :global .ant-typography {
  color: #fff !important;
  font-weight: bold;
}

.couponCode :global .ant-typography-copy {
  color: #fff;
}

.fields :global .ant-input-group-addon {
  padding: 0;
  border: none;
  background: none;
}

.fields :global .ant-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.fields :global input {
  color: #1b1c1f;
}

.tightWrapper {
  max-width: 600px;
  margin: 0 auto;
  margin-top: -10px;
}

.darkTextColor {
  color: #1b1c1f;
}
